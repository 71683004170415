import React, { useState } from 'react';
import LarisaImage from '../image/LarisaWebProfilePic.jpg';
import MichaelaImage from '../image/MichaelaProfile.jpg';
import DanProfileImage from '../image/DanProfile.jpg';

function OurPeople() {
  // State to track which profile is expanded
  const [expandedProfile, setExpandedProfile] = useState(null);

  // Sample profile data based on your images
  const profiles = [
    {
      name: 'Larisa Mathur',
      title: 'Managing Member',
      image: LarisaImage,
      description: 'Larisa is a licensed real estate broker in Florida with experience in acquisitions, sales and property management. She additionally earned a bachelor degree in Industrial Engineering and an MBA from University of New Haven, Connecticut. Larisa’s career started in  data science, and moved into project management, where she managed M&A system integrations, contract negotiations and vendor management across multiple industries from retail to aerospace. Larisa brings a sense of urgency to projects, removes impediments from the critical path, reduces inefficiencies or risks and is laser focused on profitability of initiatives, all while seamlessly managing multiple projects concurrently.',
    },
    {
      name: 'Daniel Sirlin', 
      title: 'Corporate Counsil & Member', 
      image: DanProfileImage, 
      description: 'Daniel received his law degree from Georgetown University, and is licensed to practice in Florida, New York and Washington DC, and has been in practice for over 40 years. He is the founder of Panther Real Estate Partners. Through his company he has managed large commercial development projects in Florida, with investments of up to $35 million in a single project. Daniel brings his in depth knowledge of the real estate law, navigating project requirements related to permits, deal structuring and accounting. Daniel has strong relationships with real estate development partners and rich experience in development projects. In addition to the general advisory role for fund activities, Daniel supports all legal needs of Propertunity investments including title related issues, and serve as a trustee for the fund and its assets.',
    },
    {
      name: 'Michaela Rudis',
      title: 'Head of Sales',
      image: MichaelaImage,
      description: 'Michaela is a seasoned real estate broker in Massachusetts and California with over 25 years of sales experience in residential, vacation properties and commercial, including medical offices, technology buildings and motels. Her extensive career has honed her skills in advertising and relationship development, allowing her to create impactful marketing campaigns that attract buyers and foster strong client connections. Michaela is instrumental in property positioning, utilizing her deep understanding of market trends to present homes in the most appealing way possible. She leads the go-to-market strategy, ensures properties are effectively launched and promoted to maximise visibility and attract buyers.',
    },
  ];

  // Function to toggle profile expansion
  const toggleProfile = (index) => {
    setExpandedProfile(expandedProfile === index ? null : index);
  };

  return (
    <>
      {/* Hero Section */}
      <section className="hero3">
        <div className="content">
          <div className="text-background">
            <h1>Our People</h1>
            <p>Meet The Team</p>
          </div>
        </div>
      </section>
      <div className="spacer"></div>
    
      <section className="profiles-section">
        <div className="profiles-frame">
          {profiles.map((profile, index) => (
            <div key={index} className="profile" onClick={() => toggleProfile(index)}>
              <img src={profile.image} alt={profile.name} />
              <h2>{profile.name}</h2>
              <p>{profile.title}</p>

              {expandedProfile === index && (
                <div className="expanded-profile">
                  <p>{profile.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      <div className="spacer"></div>
    </>
  );
}

export default OurPeople;
